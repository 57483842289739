<template>
  <div
    class="co-flex-col"
    :class="[
      smAndDown ? 'px-4' : 'px-16',
      smAndDown ? 'pt-4 pb-10' : 'py-8'
    ]"
  >
    <span class="text-h5 my-6">{{ title }}</span>
    <v-form
      ref="form"
      lazy-validation
    >
      <v-text-field
        v-model="txtPhone.value"
        :label="txtPhone.label"
        outlined
        required
        :prepend-inner-icon="txtPhone.prependInner"
        :rules="phoneRules"
      />

      <template v-if="authMode === authModes.sms">
        <v-text-field
          v-model="txtCode.value"
          :label="txtCode.label"
          outlined
          required
          :prepend-inner-icon="txtCode.prependInner"
          :rules="codeRules"
        >
          <template #append>
            <span
              class="pt-1"
              :class="[txtCode.color ? `${txtCode.color}--text` : 'blue-grey--text']"
              style="cursor: pointer;"
              @click="sendMsgCode"
            >
              {{ txtCode.appendInner }}
            </span>
          </template>
        </v-text-field>
      </template>
      <template v-else>
        <v-text-field
          v-model="txtPassword.value"
          :label="txtPassword.label"
          outlined
          type="password"
          required
          :prepend-inner-icon="txtPassword.prependInner"
          :rules="pwdRules"
        >
          <template #append>
            <span
              class="black--text pt-1"
              style="cursor: pointer;"
              @click="resetPassword"
            >{{ txtPassword.appendInner }}</span>
          </template>
        </v-text-field>
      </template>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-btn
            block
            depressed
            :color="authMode === authModes.password ? 'primary' : 'blue-grey lighten-5'"
            @click="toModePassword"
          >
            {{ btnPass.text }}
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-btn
            block
            depressed
            :color="authMode === authModes.sms ? 'primary' : 'blue-grey lighten-5'"
            @click="toModeSms"
          >
            {{ btnCode.text }}
          </v-btn>
        </v-col>
      </v-row>
      <v-checkbox
        v-model="chkProtocol.value"
        :rules="protocolRules"
      >
        <template #label>
          <span class="co-text-xs black--text">
            已阅读并同意我遇见最美的光
            <span
              class="primary--text"
              @click="toProtocol"
            >服务协议</span>
            和
            <span
              class="primary--text"
              @click="toPrivacy"
            >隐私政策</span>
          </span>
        </template>
      </v-checkbox>
      <!-- <div class="co-w-full co-h24" /> -->
      <v-btn
        :loading="btnLogin.loading"
        :color="btnLogin.color"
        class="co-w-full mt-2"
        height="48"
        @click="toLogin"
      >
        {{ btnLogin.text }}
      </v-btn>

      <v-btn
        :color="btnQrcode.color"
        class="co-w-full mt-4 white--text"
        height="40"
        @click="changeMode"
      >
        <v-icon
          left
          color="white--text"
        >
          {{ btnQrcode.icon }}
        </v-icon>
        {{ btnQrcode.text }}
      </v-btn>
    </v-form>
    <dialog-agreement ref="dlgAgreement" />
  </div>
</template>

<script>
  import api from '@/api/co.api'
  import app from '@/api/co.app'
  import lib from '@/api/co.lib'
  import user from '@/api/co.user'

  export default {
    name: 'LoginPhone',
    components: {
      DialogAgreement: () => import('@/pages/sections/comm/DialogAgreement.vue')
    },
    props: {},
    data () {
      return {
        title: '用户登录',
        authModes: api.captcha.Modes,
        authMode: api.captcha.Modes.sms,
        captcha: {},
        txtPhone: {
          label: '手机号码',
          prependInner: 'mdi-cellphone',
          appendInner: '',
          value: ''
        },
        txtPassword: {
          label: '登录密码',
          prependInner: 'mdi-lock-outline',
          appendInner: '忘记密码了',
          value: ''
        },
        txtCode: {
          label: '验证码',
          prependInner: 'mdi-shield-key-outline',
          appendInner: '获取验证码',
          value: '',
          timer: null,
          count: 60,
          disabled: false,
          color: 'black'
        },
        chkProtocol: {
          value: true
        },
        btnPass: {
          text: '密码登录'
        },
        btnCode: {
          text: '验证码登录'
        },
        btnLogin: {
          loading: false,
          color: 'primary',
          text: '登录'
        },
        btnQrcode: {
          color: 'green',
          icon: 'mdi-wechat',
          text: '微信扫码登录'
        },
        phoneRules: [
          val => !!val || '必须填写手机号码',
          val => /^[1][3-9][0-9]{9}$/.test(val) || '请输入正确的手机号码'
        ],
        pwdRules: [
          val => !!val || '必须填写登录密码',
          val => val.length >= 6 || '登录密码至少6位'
        ],
        codeRules: [
          val => !!val || '必须填写验证码',
          val => /^[0-9]{6}$/.test(val) || '请输入正确的验证码'
        ],
        protocolRules: [
          val => !!val || '必须同意服务协议和隐私政策',
        ]
      }
    },
    computed: {
      smAndDown () {
        return this.$vuetify.breakpoint.smAndDown
      }
    },
    methods: {
      showAgreement (type) {
        const dlg = this.$refs.dlgAgreement
        if (dlg) {
          dlg.show(type)
        }
      },
      toProtocol () {
        this.showAgreement('user-agreement')
      },
      toStatement () {
        this.showAgreement('copyright-statement')
      },
      toPrivacy () {
        this.showAgreement('privacy-policy')
      },
      clearCodeTimer () {
        if (this.txtCode.timer) {
          clearInterval(this.txtCode.timer)
          this.txtCode.timer = null
        }
      },
      startCodeTimer () {
        const me = this
        const decreaseCount = function () {
          me.txtCode.count -= 1
          if (me.txtCode.count < 1) {
            me.clearCodeTimer()
            me.txtCode.appendInner = '获取验证码'
            me.txtCode.disabled = false
            me.txtCode.color = 'black'
          } else {
            me.txtCode.appendInner = `(${me.txtCode.count}S)后重发`
          }
        }

        this.txtCode.count = 60
        this.txtCode.disabled = true
        this.txtCode.color = 'grey'
        this.txtCode.timer = setInterval(decreaseCount, 1000)
      },
      sendMsgCode () {
        const me = this
        const executing = function () {
          me.startCodeTimer()
        }

        const executed = function (res) {
          console.log('sendMsgCode, res: %o', res)
          if (res.status) {
            me.$message({
              message: '验证码发送成功！',
              type: 'success'
            })
          } else {
            me.$message({
              message: '验证码发送失败！',
              type: 'error'
            })
          }
        }

        lib.sso.sendMsgCode({
          mobile: this.txtPhone.value,
          executing,
          executed
        })
      },
      setMode (mode) {
        this.authMode = mode
      },
      toModePassword () {
        this.setMode(this.authModes.password)
      },
      toModeSms () {
        this.setMode(this.authModes.sms)
      },
      resetPassword (ev) {
        this.$emit('change-password', ev)
      },
      toLogin (ev) {
        if (!this.$refs.form.validate()) {
          return
        }

        const me = this
        const mobile = this.txtPhone.value
        const password = this.txtPassword.value
        const verCode = this.txtCode.value

        const data = {
          mobile,
          oauth: this.authMode
        }

        if (this.authMode === this.authModes.sms) {
          data.code = verCode
        } else {
          data.password = password
        }

        const executing = function () {
          me.btnLogin.loading = true
        }

        const executed = function (res) {
          // console.log('toLogin, status: %o, data:%o', res.status, res.data)
          me.btnLogin.loading = false
          if (res.status) {
            const callback = function (resp) {
              if (resp.status) {
                app.emit(app.event.USER_LOGIN, resp.data)
              }
            }
            user.token.save(res.data, callback)
          } else {
            me.$notify({
              title: '登录失败',
              message: `${res.data.code} - ${res.data.desc}`,
              type: 'error'
            })
          }
        }
        user.login.enter({
          data,
          executing,
          executed
        })
      },
      changeMode () {
        this.$emit('change', {
          mode: 'qrcode'
        })
      },
    }
  }
</script>
